<template>
  <div class="container">
    <header>
      <!-- <img src="../../assets/images/logoHead.png" width="180px" alt /> -->
      <span class="title" style="margin:0 auto;">{{manageName}}</span>
      <div class="seigin">
        <span style="margin-right:20px" @click="goHome">
          <i class="el-icon-setting"></i> 进入后台管理系统
        </span>
        <span @click="dengchu">
          <i class="el-icon-right"></i>退出
        </span>
      </div>
    </header>
    <div class="nav">
      <span style="margin-right:10px">双重预防:</span>
      <ul>
        <li @click="goList('矿山类')">矿山类</li>
        <li @click="goList('工贸类')">工贸类</li>
        <li @click="goList('化工类')">化工类</li>
        <li @click="goList('社会服务类')">社会服务类</li>
      </ul>
    </div>
    <div class="body">
      <div class="pic">
        <el-carousel style="width:100%; height:70vh">
          <el-carousel-item v-for="item in bannerList" :key="item.id">
            <img style="width:100%;height:100%" :src="item.url" alt />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="message">
        <video
          :src="this.videoUrl"
          style="width:48%; height:376px; "
          controls="controls"
        >您的浏览器不支持 video 标签。</video>
        <div class="messageBox">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="通知公告" name="first">
              <ul class="scoll_container">
                <li v-for="item in messsageList" :key="item.id" @click="goDetails(item.id)">
                  <span class="infoM">{{item.title}}</span>
                  <span class="date">{{`[${item.addtime}]`}}</span>
                </li>
              </ul>
            </el-tab-pane>
            <el-tab-pane label="课件管理" name="second">
              <ul class="scoll_container">
                <li v-for="item in classList" :key="item.id">
                  <span class="infoM" @click="goDetailsClass(item)">{{item.title}}</span>
                  <span class="downLoad" @click="hadleDown(item)">下载</span>
                  <span class="date">{{`[${item.addtime}]`}}</span>
                </li>
              </ul>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <!-- <div class="details">
        <h3>平台介绍</h3>
        <p>邦泰云安全旨在运用各种信息化手段对企业安全日常管控、事中应对、事后处理的全过程进行管理监督</p>
        <p>实现信息共享、互联协同、全员参与、安全监控等专业化、数字化</p>
        <p>帮助政府构建快速高效双重预防机制</p>
      </div>
      <div class="btLogo">
        <img src="../../assets/images/logoHead.png" width="180px" alt />
      </div>-->
      <div class="footerBox" style="margin-top:30px">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="友情链接" name="first">
            <div class="container">
              <span
                class="linka"
                v-for="item in urlList"
                :key="item.id"
                @click="goOther(item.url)"
              >{{item.name}}</span>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- <ul class="bottomClass">
        <li>
          <router-link :to="{path:'/manage/handleLog'}">操作日志</router-link>
        </li>
        <li>
          <router-link :to="{path:'/manage/companyList'}">企业列表</router-link>
        </li>
        <li>
          <router-link :to="{path:'/danger/recognize'}">风险点辨识</router-link>
        </li>
        <li>
          <router-link :to="{path:'/danger/workList'}">作业活动清单</router-link>
        </li>
        <li>
          <router-link :to="{path:'/danger/workEvaluate'}">作业活动评价清单</router-link>
        </li>
        <li>
          <router-link :to="{path:'/danger/equipment'}">设备设施清单</router-link>
        </li>
        <li>
          <router-link :to="{path:'/danger/equipmentRisk'}">设备设施风险评价清单</router-link>
        </li>
        <li>
          <router-link :to="{path:'/danger/dangerCheck'}">隐患排查清单</router-link>
        </li>
        <li>
          <router-link :to="{path:'/danger/dangerPeople'}">隐患指派人管理</router-link>
        </li>
      </ul>-->
    </div>

    <div class="fotters" style="padding-bottom:30px">
      <p class="fotter">打造服务行业领先品牌</p>
      <p class="fotter">Copyright 2020 ©河南邦泰合力管理咨询有限公司 版权所有 豫ICP备11032258号</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      urlList: [],
      classList: [],
      messsageList: [],
      videoUrl: '',
      bannerList: [],
      activeName: 'first',
      manageName: '',
      lunboList: [
        {
          id: 1,
          url: require('../../assets/images/bigpic.jpg'),
        },
        {
          id: 2,
          url: require('../../assets/images/bigpic.jpg'),
        },
        {
          id: 3,
          url: require('../../assets/images/bigpic.jpg'),
        },
      ],
      newsList: [
        {
          id: 1,
          name: '武国定：扎实做好防灾减灾和安全生产工作',
          pic: 'https://img.henan.gov.cn/9fabce91e74b4ec0ebe67ab9f13c1f97?p=0',
        },
        {
          id: 2,
          name: '国务院安委会考核巡查河南安全生产和消防工作',
          pic: 'https://img.henan.gov.cn/cfb3aa8e7422fa6bad07ad97145d322d?p=0',
        },
        {
          id: 3,
          name: '河南省应急管理系统开展义马气化厂“7·19”',
          pic: 'https://img.henan.gov.cn/2a349fade3252e730bef7de428888eff?p=0',
        },
      ],
    }
  },
  methods: {
    goOther(url) {
      let urls = url.split('/')[0]
      // return console.log(urls)
      // if (urls != 'http:') {
      //   window.open('http://' + url)
      // }
      window.open(url)
    },
    handleClick(tab, event) {
      console.log(tab, event)
    },
    goNews(id) {
      console.log('val', id)

      this.$router.push({
        path: '/news',
        query: {
          nId: id,
        },
      })
    },
    goList(val) {
      this.$router.push({
        path: '/manage/companyList',
        // path: '/manage/first',
        query: {
          class: val,
        },
      })
    },
    goHome() {
      this.$router.push('/manage/first')
    },
    dengchu() {
      window.localStorage.clear()
      this.$router.push('/login')
    },
    goHomepage() {
      window.location.href = 'http://www.bangtaiheli.com/'
    },
    goDetails(id) {
      this.$router.push({
        path: '/news',
        query: {
          id: id,
        },
      })
    },
    goDetailsClass(item) {
      // window.open(url)
      let fileName = item.url.lastIndexOf('.') //取到文件名开始到最后一个点的长度
      let fileNameLength = item.url.length //取到文件名长度
      let fileFormat = item.url.substring(fileName + 1, fileNameLength) //截
      console.log(fileFormat)
      if (fileFormat == 'pdf' || fileFormat == 'PDF') {
        const a = document.createElement('a') // 创建a标签
        a.setAttribute('download', item.content) // download属性
        a.setAttribute('href', item.url) // href链接
        return a.click()
      }
      window.open(
        'https://view.officeapps.live.com/op/view.aspx?src=' + item.url
      )
    },

    hadleDown(item) {
      console.log(item)
      // window.open(url)
      // window.open(url)
      const a = document.createElement('a') // 创建a标签
      a.setAttribute('download', item.content) // download属性
      a.setAttribute('href', item.url) // href链接
      a.click()
    },
    // *获取轮播
    async getLunbo() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('type', 1)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Homepage/bannerList',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      console.log(res)
      this.bannerList = res.datas
    },
    // *获取通知列表
    async getMessages() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('style', 1)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Homepage/indexNewsList',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.messsageList = res.datas
      console.log('通知公告', this.messsageList)
    },
    // *获取视频
    async getVideo() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Homepage/video',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.videoUrl = res.datas.url
      console.log(this.videoUrl)
    },
    // *获取课件
    async getClass() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('style', 2)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Homepage/indexNewsList',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.classList = res.datas
      console.log('kejian', this.classList)
    },
    // *获取链接
    async getUrlList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/homepage/linkShow',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.urlList = res.datas
      console.log('链接+++++++++++++', this.urlList)
    },
  },
  created: function () {
    this.manageName = window.localStorage.getItem('btlh_nickname') || ''
    if (this.manageName) {
      document.title = this.manageName + '-邦泰云安全'
    }
    this.token = localStorage.getItem('btlh_token') || ''
    if (this.manageName) {
      document.title = this.manageName + '-邦泰云安全'
    }
    this.getLunbo()
    this.getVideo()
    this.getMessages()
    this.getClass()
    this.getUrlList()
  },
}
</script>
<style lang="less" scoped>
.container {
  width: 100%;
}
header {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 200px;
  .title {
    // margin-left: 100px;
    // font-size: 20px;
    font-size: 32px;
    // color: #fb0103;
    color: #364666;
    font-weight: 700;
    margin-right: 200px;
  }
  .seigin {
    font-size: 14px;
    cursor: pointer;
    color: #1356a7;
  }
}
.nav {
  display: flex;
  align-items: center;
  color: #fff;
  // background-color: #c90a09;
  background-color: #527dd3;
  // background-color: #c20d07;

  height: 50px;
  width: 100%;
  justify-content: center;
  ul {
    width: 1200px;
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 100%;
    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 100%;
      width: 15%;
      justify-content: center;
      // margin-left: 30px;
    }
    li:hover {
      background-color: #3b5b9a;
      // background-color: #941313;
    }
  }
}
.body {
  box-sizing: border-box;
  width: 100%;
  padding: 0 10%;
  .pic {
    text-align: center;
    width: 100%;
    img {
      width: 100%;
    }
  }
}
.message {
  margin-top: 50px;
  display: flex;
}
.messageBox {
  width: 48%;
  margin-left: 30px;
  /deep/ .el-tabs__item {
    font-size: 20px;
  }
  /deep/ .el-tabs__active-bar {
    // display: none;
  }
  /deep/ .el-tabs__nav-wrap::after {
    // display: none;
  }
  h3 {
    margin: 0;
    font-size: 20px;
    height: 39px;
    line-height: 40px;
    border-bottom: 1px solid #eee;
  }
  ul {
    box-sizing: border-box;
    width: 100%;
    padding: 0 20px;
    margin: 0;
    list-style: none;
    li {
      height: 40px;
      line-height: 40px;
      width: 100%;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      .infoM {
        display: block;
        width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .date {
        // float: right;
        color: #999;
      }
    }
  }
}
/deep/ .el-carousel__button {
  width: 15px;
  height: 15px;
  border-radius: 8px;
}
/deep/.el-carousel__indicators--horizontal {
  width: 20%;
  height: 40px;
  left: 90%;
}
/deep/ .el-carousel__item {
  h3 {
    margin: 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 10%;
    background-color: rgba(255, 255, 255, 0.5);
    padding-left: 30px;
    span {
      width: 60%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.bottomClass {
  padding: 0;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  margin-top: 100px;
  li {
    color: #fff;
    font-size: 20px;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33.3333%;
    cursor: pointer;
    transition: all 0.5s ease 0s;
  }
  li:hover {
    font-size: 23px;
  }

  li:nth-child(1) {
    background: url(../../assets/images/z1.jpg) no-repeat;
    background-size: 100% 100%;
  }
  li:nth-child(2) {
    background-color: #4fbcfb;
  }
  li:nth-child(3) {
    background: url(../../assets/images/z2.jpg) no-repeat;
    background-size: 100% 100%;
  }
  li:nth-child(4) {
    background-color: #f7b680;
  }
  li:nth-child(5) {
    background: url(../../assets/images/z3.jpg) no-repeat;
    background-size: 100% 100%;
  }
  li:nth-child(6) {
    background-color: #a6b8c4;
  }
  li:nth-child(7) {
    background: url(../../assets/images/z1.jpg) no-repeat;
    background-size: 100% 100%;
  }
  li:nth-child(8) {
    background-color: #4fbcfb;
  }
  li:nth-child(9) {
    background: url(../../assets/images/z2.jpg) no-repeat;
    background-size: 100% 100%;
  }
}
.fotters {
  color: #999;
  margin-top: 100px;
  margin-bottom: 50px;
  text-align: center;
  .fotter {
    cursor: pointer;
    text-align: center;
  }
}
.details {
  h3 {
    margin-top: 50px;
    text-align: center;
    font-size: 34px;
    color: #555;
  }
  p {
    text-align: center;
    color: #003265;
    font-size: 16px;
  }
}
a {
  color: #fff;
  text-decoration: none;
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/.el-carousel__container {
  height: 100%;
}
/deep/ .el-carousel__item {
  height: 100%;
}
/deep/ .el-carousel--horizontal {
  overflow: hidden;
}
video,
video:hover,
video:active,
video:visited,
video:link,
video:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  -webkit-tap-highlight-color: transparent;

  outline: none;

  background: none;

  text-decoration: none;
}
.scoll_container {
  height: 320px;
  overflow: auto;
}
.downLoad {
  cursor: pointer;
}
.downLoad:hover {
  color: #409eff;
}
.footerBox {
  margin-top: 4 0px;
  .container {
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
    .linka {
      padding-bottom: 5px;
      display: block;
      cursor: pointer;
      font-weight: 700;
      color: #000;
      font-size: 16px;
      margin-right: 10px;
    }
  }

  .linka:hover {
    text-decoration: underline;
  }
  /deep/ .el-tabs__item {
    font-size: 20px !important;
  }
  /deep/.el-tabs__active-bar {
    width: 80px !important;
  }
}
.btLogo {
  width: 100%;
  margin-bottom: 10px;
  overflow: hidden;
  img {
    float: right;
  }
}
</style>